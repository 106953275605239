import React from "react";
import HnF from "../../../UI/HnF";
import Container from "../../../UI/Container";
import TeamCard from "./TeamCards";
import BgGrad from "../../../UI/BgGrad";
import AbtIntro from "./AbtIntro";
import CoreValue from "./CoreValue";
import Abt from "./Abt";
import Team from "../Homepage/Team";

export default function About() {
  return (
    <div className="wrap text-gray-200">
      <HnF>
        <AbtIntro />
        <Abt />
        <CoreValue />
        <Team />
      </HnF>
    </div>
  );
}
