import { NavLink } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const dashLinks = [
  {
    path: "",
    title: "Dashboard",
    icon: <LuLayoutDashboard />,
  },
  {
    path: "verify-ticket",
    title: "Verify Ticket",
    icon: <RiVerifiedBadgeFill />,
  },
  //   {
  //     id: 3,
  //     path: "More",
  //     title: "More",
  //     src: "/assets/dashboard.png",
  //     wsrc: "/assets/dashboardwhite.png",
  //   },
];

const SideBar: React.FC = () => {
  const today = new Date();
  const day = today.toLocaleDateString("en-US", { weekday: "long" });
  return (
    <div className="fixed   h-full w-[250px]   top-0 left-0 bottom-0 px-10 border-r pt-10 overflow-auto bg-secondary z-20 border-1 border-gray-500">
      <div className="flex flex-col justify-center items-center w-full">
        <p className="font-bold content-center py-10">{day}</p>
        <nav>
          <ul className="mt-10 flex flex-col gap-6">
            {dashLinks.map(({ path, title, icon }, index) => (
              <li key={index}>
                <NavLink
                  to={path}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? " flex bg-primary rounded-lg text-black px-5 py-2 gap-2 text-sm w-full items-center"
                      : "text-sm flex gap-2 px-5 py-2 bg-none text-white text-nowrap items-center"
                  }
                >
                  {/* <img src={wsrc} height={16} width={16} /> */}
                  {icon}
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
