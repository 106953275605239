import { Outlet } from "react-router-dom";
import SideBar from "../Components/SideBar";
import { ReactNode, useState } from "react";
import Modal from "../Components/Modal";
import { FaUserCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

const DashboardLayout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <div className="text-white flex gap-4 w-full h-full bg-secondary">
      <SideBar />
      <main className="w-full overflow-hidden h-full relative z-10 bg-secondary">
        <div className="ml-[250px]">
          {/* <div className="border-b border-1 border-gray-500 h-[100px] w-full bg-secondary"></div> */}

          <div className="border-b border-1 border-gray-500 h-[100px] w-full bg-secondary flex items-center justify-between px-6">
            {/* Left section: Avatar Icon and Name */}
            <div className="flex items-center gap-4">
              <FaUserCircle className="text-white text-3xl" />
              <span className="text-white text-lg font-medium">
                Welcome, <span className="text-primary">Admin</span>
              </span>
            </div>

            {/* Right section: Logout Button with Icon */}
            <button
              onClick={() => {
                // Add your logout logic here
              }}
              className="flex items-center gap-2 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200"
            >
              <FiLogOut className="text-xl" />
              <span>Logout</span>
            </button>
          </div>

          <div className="graymint right-4 top-32"></div>
          <Outlet context={{ toggleModal, setModalContent }} />
        </div>
      </main>
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        content={modalContent}
      />
    </div>
  );
};

export default DashboardLayout;
