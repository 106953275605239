import { FaCircle, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";

interface CartProps {
	name?: string;
	prize?: string;
	colour?: string;
	size?: string;
	quantity?: string;
}

const Item: React.FC<CartProps> = ({}) => {
	return (
		<div className="items w-full py-3 border-b border-b-gray-500 pb-5">
			<div className="flex flex-col lg:flex-row gap-2 w-full">
				<div className="img-wrap">
					<img
						src="/assets/product/croctop.png"
						alt="Croctop"
						className="w-[150px] relative z-20"
					/>
				</div>
				<div className="text-wrap w-full relative z-10">
					<div className="flex justify-between items-center">
						<h3 className="text-white font-bold text-3xl">
							Defi Summer Croptop
						</h3>
						<p className="text-primary font-bold text-lg">
							#34,000
						</p>
					</div>

					<div className="flex gap-3 my-5 text-white">
						<div className="wrap flex gap-1 font-thin items-center">
							<FaCircle className="text-green-500" />{" "}
							<span>Green</span>
						</div>
						<div className="wrap">
							<span>Size:</span>{" "}
							<span className="font-bold">35</span>
						</div>
					</div>

					<div className="flex justify-between items-center">
						<div className="flex items-center text-sm gap-2 font-bold bg-black p-2 rounded-md text-white">
							<span className="border border-dotted p-1">
								<FaPlus />
							</span>{" "}
							<input
								type="text"
								className="w-[50px] bg-transparent text-center"
								value={1}
							/>
							<span className="border border-dotted p-1">
								<FaMinus />
							</span>
						</div>
						<button className="text-red-500 flex gap-2 items-center rounded-xl border border-red-500 px-4 p-2">
							<FaTrashAlt />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Item;
