import { FC } from "react";
import { MdOutlineSend } from "react-icons/md";

interface TaskCardProps {
	image?: string;
}

const TaskCard: FC<TaskCardProps> = ({}) => {
	return (
		<div className="card border border-primary rounded-xl p-5 min-w-[300px] ">
			<div className="header text-white font-bold text-lg mb-3">
				<h1>Follow NFTng on X.</h1>
			</div>
			<div className="flex gap-3 items-center">
				<img src="/assets/icons/x.png" alt="X" />{" "}
				<input
					type="text"
					placeholder="Submit X Username"
					className="bg-transparent border border-white border-opacity-30 rounded-xl px-3 py-2 w-full"
				/>
				<div className="wrap">
					<button className="flex p-3 bg-primary text-black rounded-full">
						<MdOutlineSend />
					</button>
				</div>
			</div>
		</div>
	);
};

export default TaskCard;
