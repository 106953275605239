import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { verifyTicketId } from "../../../Services/api-service/ticket-api-service";

type CodeArray = string[];

interface DashboardLayoutContext {
  toggleModal: () => void;
  setModalContent: (content: React.ReactNode) => void;
}

const VerifyTicket: React.FC = () => {
  const { toggleModal, setModalContent } =
    useOutletContext<DashboardLayoutContext>();

  const [code, setCode] = useState<CodeArray>(Array(13).fill("")); // 13 inputs
  // const [ticketData, setTicketData] = useState<any>({});
  let ticketData: any = {};

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (index: number, value: string): void => {
    const newCode = [...code];
    newCode[index] = value.slice(0, 1); // Take only the first character
    setCode(newCode);
    if (value && index < 12) {
      inputRefs.current[index + 1]?.focus(); // Move focus to the next input
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const ticket_id = [
      code.slice(0, 5).join(""),
      code.slice(5, 9).join(""),
      code.slice(9, 13).join(""),
    ].join("-");

    try {
      const response = await verifyTicketId(ticket_id);
      ticketData = response;
      console.log(response);
      handleOpenModal(); // Open modal on successful verification
    } catch (error) {
      alert("Ticket not found!");
      console.log(error);
    }
  };

  const handleOpenModal = () => {
    setModalContent(
      <div className="flex flex-col gap-5 p-3">
        <p className="text-xl capitalize">
          {ticketData?.firstname} {ticketData?.lastname} Ticket Data
        </p>
        <div className="text-sm font-medium flex justify-between ">
          <div className="flex flex-col justify-between items-start gap-4 text-xs text-gray-300">
            <p>Id</p>
            <p>Price</p>
            <p>Quantity</p>
            <p>Ticket Type</p>
            <p>Validity</p>
          </div>
          <div className="flex flex-col justify-between items-start gap-4 text-xs text-gray-300">
            <span>-----------------------------------</span>
            <span>-----------------------------------</span>
            <span>-----------------------------------</span>
            <span>-----------------------------------</span>
          </div>
          <div className="flex flex-col justify-between items-start gap-4">
            <p className="flex">{ticketData?.ticket_id}</p>
            <p className="flex">${ticketData?.price}</p>
            <p className="flex">{ticketData?.quantity}</p>
            <p className="flex">...</p>
            <p className="flex gap-1 justify-start">
              <span>
                <div className="flex">
                  <span
                    className={`text-xs p-1 rounded-full px-5 text-white ${
                      ticketData?.isUsed ? "bg-red-500" : "bg-green-500"
                    }`}
                  >
                    {ticketData?.isUsed ? "Ticket Used" : "Valid"}
                  </span>
                </div>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
    toggleModal();
  };

  return (
    <div className="flex flex-col h-full justify-center items-center mb-[1000px] ">
      <div className="bg-tertiary mt-32 flex-col rounded-xl border border-1 border-gray-500 max-w-[600px]">
        <p className="p-2">VerifyTicket</p>
        <div className="flex flex-col bg-secondary items-center justify-center gap-3 w-full rounded-b-xl p-5">
          <p className="text-lg font-medium">Ticket Verification</p>
          <p className="text-xs text-gray-300">Input 12 numbers here</p>
          <form onSubmit={handleSubmit}>
            <div className="flex  justify-center items-center">
              {code.map((digit, index) => (
                <React.Fragment key={index}>
                  {index === 5 || index === 9 ? (
                    <span className="text-xl text-gray-400">-</span>
                  ) : null}
                  <input
                    type="text"
                    maxLength={1}
                    value={digit}
                    required
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="customInput"
                  />
                </React.Fragment>
              ))}
            </div>
            <button
              type="submit"
              className="bg-primary py-1 rounded-xl flex w-full items-center justify-center font-medium text-black mt-10"
            >
              Check Ticket
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyTicket;
