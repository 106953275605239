import { MdCheckBox } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const TicketCryptoPaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="wrap fixed w-full h-full top-0 bottom-0 right-0 backdrop-blur-lg flex justify-center items-center z-[400] bg-black bg-opacity-50">
      <div className="text-xl flex flex-col gap-3 justify-center items-center text-center">
        <div className="wrap h-[100px] w-[100px] bg-primary rounded-lg flex justify-center items-center">
          <MdCheckBox size={80} />
        </div>
        <p className="text-white text-sm lg:text-lg px-5">
          Yay! Ticket Purchase Successfully. <br /> Kindly check your email for
          your ticket
        </p>
        <button
          className="bg-primary text-black text-sm p-2 rounded-lg"
          onClick={() => navigate("/")}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default TicketCryptoPaymentSuccess;
