import React from "react";
import { FaLinkedin, FaTwitter } from "react-icons/fa";

interface TeamCardsProps {
  imgLink: string;
  position: string;
  name: string;
  twitter?: string;
  LinkedIn?: string;
}
const TeamCard: React.FC<TeamCardsProps> = ({
  imgLink,
  name,
  position,
  twitter,
  LinkedIn,
}) => {
  return (
    <div className="wrap flex flex-col gap-2 items-center text-white ">
      <div className="relative img-wrap h-[250px] w-full overflow-hidden bg-black rounded-xl border border-white border-opacity-20">
        <img
          src={imgLink}
          alt="Team member"
          className="w-full h-full object-cover" // Ensure image covers the full width and height
        />
        <div className="absolute bottom-0 z-10 w-full  p-2">
          <div className="bg-black bg-opacity-70 backdrop-blur-sm rounded-xl p-2">
            <h1 className="font-medium text-xs">{name}</h1>

            <div className="flex justify-between items-center">
              <span className="font-thin text-[10px]">{position}</span>
              <div className="socials flex items-center gap-3 text-[10px]">
                <a href={LinkedIn} target="_blank">
                  <FaLinkedin />
                </a>
                <a href={twitter} target="_blank">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
