import { http, createConfig, injected } from "@wagmi/core";
import { bsc } from "viem/chains";
// import { bsc } from "@wagmi/core/chains";
// import { injected } from "@wagmi/connectors";

export const config = (createConfig as any)({
  chains: [bsc],
  connectors: [injected()],
  transports: {
    [bsc.id]: http(),
  },
  // ssr: true,
});
