import React from "react";
import { FaInstagram, FaLinkedin, FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Socials() {
  return (
    <div className="wrap py-16">
      <div className="text-center text-white mb-10">
        <h1 className="text-3xl">
          Stay <span className="text-primary">Connected</span>
        </h1>
        <span className="text-xs opacity-50">
          Follow us on our social media platform <br /> for more updates,
          inquiries and informations
        </span>
      </div>
      <div className="flex flex-wrap gap-5 md:gap-16 justify-center items-center text-primary text-4xl">
        <a href="#" className="bg-secondary-100 p-3 rounded-xl items-center">
          <FaXTwitter className="mx-auto text-lg" />
          <p className="text-xs text-white opacity-60 mt-2">@NFTNG_</p>
        </a>
        <a href="#" className="bg-secondary-100 p-3 rounded-xl items-center">
          <FaTelegram className="mx-auto text-lg" />
          <p className="text-xs text-white opacity-60 mt-2">@NFT_NG</p>
        </a>
        <a href="#" className="bg-secondary-100 p-3 rounded-xl items-center">
          <FaInstagram className="mx-auto text-lg" />
          <p className="text-xs text-white opacity-60 mt-2">@NFT_NG</p>
        </a>
        <a href="#" className="bg-secondary-100 p-3 rounded-xl items-center">
          <FaLinkedin className="mx-auto text-lg" />
          <p className="text-xs text-white opacity-60 mt-2">@NFT_NG</p>
        </a>
      </div>
    </div>
  );
}
