import React from "react";
import Header from "./Header";
import MbHeader from "./MbHeader";

interface HnFProps {
	children: React.ReactNode;
}

const HnF: React.FC<HnFProps> = ({ children }) => {
	return (
		<>
			<Header />
			<MbHeader />
			{children}
		</>
	);
};

export default HnF;
