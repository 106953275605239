import React, { useEffect, useState } from "react";
import HnF from "../../../UI/HnF";
import { RiTimerFlashFill } from "react-icons/ri";
import { IoCalendar } from "react-icons/io5";
import { BsHouseFill } from "react-icons/bs";
import tty from "./tty.png";
import {
  createTicket,
  verifyPayment,
} from "../../../Services/api-service/ticket-api-service";
import { BarLoader, ScaleLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import TicketPaymentLoader from "../../../UI/TicketPaymentLoader";
import CryptoPayButton from "../../../UI/CryptoPayButton";
import TicketCryptoPaymentSuccess from "../../../UI/TicketCryptoPaymentSuccess";

interface TicketInterface {
  firstname: string;
  lastname: string;
  email: string;
  quantity: number;
  type: "regular" | "table";
}

export default function Ticket() {
  const [formData, setFormData] = useState<TicketInterface>({
    firstname: "",
    lastname: "",
    email: "",
    quantity: 0,
    type: "regular",
  });

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<any>("false");

  // For Crypto payment
  const [payment, setPayment] = useState("");
  const [hash, setHash] = useState("");

  const url = window.location.href;
  const getUrl = new URL(url);
  const reference = getUrl.searchParams.get("reference");
  useEffect(() => {
    if (!reference) return;
    verifyPaymentHandler(reference);
    // setReference(reference);
  }, []);

  // Cryptopayment Submit Handler
  useEffect(() => {
    if (payment === "crypto") {
      submitHandler();
    }
  }, [payment]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTicketTypeToggle = (isTable: boolean) => {
    setFormData((prev) => ({
      ...prev,
      type: isTable ? "table" : "regular",
    }));
  };

  // const createTicket = (ticketData: TicketInterface) => {
  //   // Replace this with actual API call or backend integration
  //   console.log("Ticket Data Sent:", ticketData);
  //   alert("Ticket created successfully!");
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    submitHandler();
  };

  const submitHandler = async () => {
    try {
      if (loading) return;

      // Basic validation: Check if all fields are filled
      if (
        !formData.firstname ||
        !formData.lastname ||
        !formData.email ||
        formData.quantity === 0
      ) {
        setError("All fields are required.");
        return;
      }

      setLoading(true);
      setError("");
      const response = await createTicket(formData, payment, hash);
      console.log(response);
      if (response.message.payment_link === "crypto paid") return;
      window.location.href = response.message.payment_link;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyPaymentHandler = async (validReference: string) => {
    try {
      const response = await verifyPayment(validReference);
      console.log(response);
      setIsVerified(true);
    } catch (error) {
      console.log(error);
      setIsVerified("close");
    }
  };

  const ticketPrice = formData.type === "regular" ? 200 : 800;
  const totalCost = formData.quantity * ticketPrice;

  return (
    <HnF>
      {reference && <TicketPaymentLoader verify={isVerified} />}
      {payment === "crypto" && !loading && <TicketCryptoPaymentSuccess />}
      <div className="wrap py-10 lg:py-32 text-white">
        <div className="header text-center py-5 rounded-lg lg:w-1/2 mx-auto lg:my-5">
          <h1 className="text-2xl lg:text-4xl font-bold">
            NFTNG <span className="text-primary">Brunch</span> Event.
          </h1>
          <span className="text-gray-200">Eat | Drink | Network</span>
        </div>
        <form onSubmit={handleSubmit} className="grid lg:grid-cols-2">
          <div className="wrap px-8 relative">
            <img
              src="/assets/v2/blur2.png"
              alt="half-circle"
              className="absolute left-0 inset-0 my-auto"
            />
            <div className="form py-5 md:px-5 relative z-10 backdrop-blur-md bg-black bg-opacity-50">
              <div className="header">
                <div className="pill grid grid-cols-2 rounded-full p-3 bg-primary text-center relative border border-primary">
                  <div
                    className={`w-1/2 rounded-full p-3 absolute bg-black top-0 bottom-0 transition-all ${
                      formData.type === "table" ? "left-1/2" : "left-0"
                    }`}
                  ></div>
                  <span
                    className={`relative z-10 transition-all cursor-pointer text-black ${
                      formData.type === "regular" && "text-white"
                    }`}
                    onClick={() => handleTicketTypeToggle(false)}
                  >
                    Regular
                  </span>
                  <span
                    className={`relative z-10 transition-all cursor-pointer text-black ${
                      formData.type === "table" && "text-white"
                    }`}
                    onClick={() => handleTicketTypeToggle(true)}
                  >
                    Table
                  </span>
                </div>

                <div className="input-wrap">
                  <div className="grid bg-primary bg-opacity-20 p-5 rounded-lg my-5 gap-3">
                    <div className="flex justify-between">
                      <span className="flex items-center text-primary gap-2">
                        <RiTimerFlashFill /> Time
                      </span>
                      <span className="font-bold text-right">4PM GMT</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="flex items-center text-primary gap-2">
                        <IoCalendar /> Date
                      </span>
                      <span className="font-bold text-right">
                        8th of Dec, 2024
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-10">
                      <span className="flex items-center text-primary gap-2">
                        <BsHouseFill /> Venue
                      </span>
                      <span className="font-bold text-right">
                        Duna Dura Resturant. Abuja.
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <input
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      placeholder="Firstname"
                      className="bg-secondary-100 rounded-lg p-3 text-center placeholder:text-gray-500 capitalize w-full"
                      required
                    />
                    <input
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleInputChange}
                      placeholder="Lastname"
                      className="bg-secondary-100 rounded-lg p-3 text-center placeholder:text-gray-500 capitalize w-full"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="abc@example.com"
                      className="bg-secondary-100 rounded-lg p-3 text-center placeholder:text-gray-500 w-full col-span-2"
                      required
                    />
                    <select
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleInputChange}
                      className="bg-secondary-100 rounded-lg p-3 text-center placeholder:text-gray-500 capitalize"
                      required
                    >
                      <option value={0} className="text-gray-500">
                        How many tickets?
                      </option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                  {error && <div className="text-red-500">{error}</div>}
                  <div className="grid bg-secondary bg-opacity-80 p-5 rounded-lg my-5 gap-3">
                    <div className="flex justify-between">
                      <span className="flex items-center text-primary gap-2">
                        Price per {formData.type}
                      </span>
                      <span>{ticketPrice}$</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="flex items-center text-primary gap-2">
                        Total Ordered
                      </span>
                      <span>x{formData.quantity}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="flex items-center text-primary gap-2">
                        Total Cost
                      </span>
                      <span>{totalCost}$</span>
                    </div>
                  </div>
                  <div className="btn-wrap mt-5">
                    {hash === "" && (
                      <button
                        type="submit"
                        className="p-3 rounded-lg bg-primary text-black flex items-center justify-center gap-5 text-sm w-full hover:bg-opacity-80 hover:text-white transition-all "
                      >
                        {loading ? <BarLoader /> : "Pay with Card"}
                      </button>
                    )}
                    {payment === "crypto" && (
                      <button
                        className="p-3 rounded-lg bg-white text-black text-sm w-full hover:bg-opacity-80 hover:text-white transition-all mt-3"
                        // onClick={() => alert("Coming soon..")}
                        type="submit"
                      >
                        {loading ? <BarLoader /> : "Pay with Card"}
                      </button>
                    )}
                    <CryptoPayButton
                      setHash={setHash}
                      setPayment={setPayment}
                      price={totalCost}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap hidden relative lg:flex items-center">
            <img
              src="/assets/v2/half-circle.png"
              alt="half-circle"
              className="absolute right-0 top-0"
            />
            <img
              src="/assets/v2/half-circle.png"
              alt="half-circle"
              className="absolute left-0 bottom-0 rotate-[180deg]"
            />
            <img src={tty} alt="Party" className="m-auto" />
          </div>
        </form>
      </div>
    </HnF>
  );
}
