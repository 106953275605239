import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SidebarProps } from "./Sidebar";
import { FiUser } from "react-icons/fi"; // Import the icon you use in the example

const Submenu: FC<SidebarProps> = ({ menuData }) => {
	return (
		<div className="sub-sidebar hidden lg:block rounded-xl overflow-clip bg-secondary-100">
			<div className="Header p-10 bg-primary">
				<h1 className="text-xl font-bold">Dashboard</h1>
			</div>

			<div className="sidebar flex flex-col gap-10 text-sm items-center text-white bg-secondary-100 px-3 rounded-xl py-10">
				{menuData.map((item, index) => (
					<NavLink
						key={index}
						to={item.link}
						className={({ isActive }) =>
							isActive
								? "pl-3 border-l-[6px] border-l-primary text-black w-full"
								: "pl-3 text-white w-full "
						}>
						{({ isActive }) => (
							<p
								className={`flex items-center gap-2 p-2 rounded-lg hover:bg-secondary hover:text-white transition-all ${
									isActive
										? "bg-primary text-black"
										: "text-white"
								}`}>
								{item.icon} <span>{item.name}</span>
							</p>
						)}
						{/* <p className="flex items-center gap-2 bg-primary p-2 rounded-lg hover:bg-secondary hover:text-white transition-all w-full">
							{item.icon} <span>{item.name}</span>
						</p> */}
					</NavLink>
				))}
			</div>
		</div>
	);
};

export default Submenu;
