import { useQuery } from "@tanstack/react-query";
import {
  getTicketSaleStats,
  getTicketStats,
} from "../../../Services/api-service/ticket-api-service";
import { useEffect, useState } from "react";

const Members = [
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
  {
    name: "David evans",
    ticket: "0x3592****456",
    visibility: "correct",
    payment: "Crpto",
    date: "15-11-2024",
  },
];

const DashboardHome = () => {
  const today = new Date();
  const formatedDay = today.toLocaleDateString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
  const [limit, setLimit] = useState<any>(10);

  const { data: tickets = [], refetch } = useQuery({
    queryKey: ["get-tickets", limit],
    queryFn: () => getTicketStats({ limit, sortOrder: "asc" }),
    enabled: false, // Disable automatic fetch on mount
  });

  // Refetch whenever 'limit' changes
  useEffect(() => {
    if (limit !== undefined) {
      refetch();
    }
  }, [limit, refetch]);

  const { data: stats } = useQuery({
    queryKey: ["get-tickets-stats"],
    queryFn: () => getTicketSaleStats(),
  });

  console.log(tickets);

  return (
    <div className="text-white flex flex-col w-full mb-[200px] bg-secondary">
      <div className="ml-10 mt-10 flex flex-col gap-10">
        <p className="text-sm">{formatedDay}</p>
        <p className="text-2xl font-medium">Nftng, Admin Dashboard</p>
        <div className="bg-tertiary rounded-full p-4 tracking-tighter flex max-w-[70%] justify-between">
          <div className="flex gap-2 items-center justify-center">
            <p className="text-md font-medium">${stats?.totalRevenue}</p>
            <span className="text-sm">Total Revenue</span>
          </div>
          <span>|</span>
          <div className="flex gap-2 items-center justify-center">
            <p className="text-md font-medium">{stats?.totalTicketsPaid}</p>
            <span className="text-sm">Total Paid Ticket</span>
          </div>
          <span>|</span>
          <div className="flex gap-2 items-center justify-center">
            <p className="text-md font-medium">{stats?.totalTicketsSold}</p>
            <span className="text-sm">Total Sold tickets</span>
          </div>
          <div className="flex gap-2 items-center justify-center">
            <p className="text-md font-medium">{stats?.totalTicketsUnsold}</p>
            <span className="text-sm">Total Unsold tickets</span>
          </div>
        </div>
        <div className="border rounded-2xl bg-tertiary mr-10  border-gray-500 border-1">
          <div className="flex justify-between py-4 px-4">
            <div className="flex gap-4">
              <p className="text-sm">Member</p>
              <select className="bg-tertiary border rounded-full text-xs">
                <option selected>This Week</option>
                <option>This Month</option>
                <option>This Year</option>
                <option>This Month</option>
              </select>
            </div>
            <p
              className="text-sm cursor-pointer"
              onClick={() => setLimit(10000)}
            >
              {" "}
              See all
            </p>
          </div>
          <table className="w-[100%] bg-secondary rounded-b-2xl border-t border-gray-500 border-1 text-left">
            <thead className="font-bold text-primary">
              <tr>
                <th className="px-4 py-5">Name</th>
                <th className="px-4 py-5">Ticket</th>
                <th className="px-4 py-5">Validity</th>
                <th className="px-4 py-5">Payment</th>
                <th className="px-4 py-5">Date</th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-300">
              {tickets?.map((i: any, index: any) => (
                <tr
                  key={index}
                  className="border-b  border-gray-500 border-1 hover:bg-tertiary cursor-pointer transition-all last:border-0"
                >
                  <td className="p-4">
                    {i.firstname} {i.lastname}
                  </td>
                  <td className="p-4">{i.ticket_id}</td>
                  <td className="p-4">{i.isUsed ? "Invalid" : "Valid"}</td>
                  <td className="p-4">{i.price}</td>
                  <td className="p-4">
                    {new Date(i.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
