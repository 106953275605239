import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Layout from "../Layout";
import { VscDebugBreakpointData } from "react-icons/vsc";
import { MdOutlineLeaderboard } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import Container from "../../../UI/Container";

export default function Profile() {
	return (
		<Layout>
			<div className="wrap">
				<div className="b3 rounded-t-3xl">
					<div className="header bg-black bg-opacity-70  h-[150px] px-4 py-10 rounded-t-3xl">
						<h1 className="text-white font-bold text-3xl">
							Profile
						</h1>
					</div>
				</div>
				<Container>
					<div className="profile flex justify-between py-4">
						<div className="wrap flex itc gap-4 text-white">
							<div className="img-wrap rounded-full border-2 border-primary bg-secondary h-[150px] w-[150px] overflow-clip flex items-center translate-y-[-40px]">
								<img src="/assets/team/someh.png" alt="User" />
							</div>

							<div className="details pb-6">
								<h1 className="text-xl font-bold">User_0112</h1>
								<span className="text-gray-400 my-3">
									ibrahimsheriff999@gmail.com
								</span>
								<p className="flex gap-4 text-gray-600">
									<span className="flex gap-1 items-center text-gray-300">
										<VscDebugBreakpointData color="#a1d283" />{" "}
										110k
									</span>
									|
									<span className="flex gap-1 items-center text-gray-300">
										<AiOutlineUsergroupAdd color="#a1d283" />{" "}
										22
									</span>
									|
									<span className="flex gap-1 items-center text-gray-300">
										<MdOutlineLeaderboard color="#a1d283" />{" "}
										75
									</span>
								</p>
							</div>
						</div>

						<div className="wrap">
							<div className="btn-wrap flex items-center gap-10 text-primary">
								<button className="bg-primary text-black px-3 py-2 flex items-center gap-3 rounded-lg">
									<IoSettingsOutline /> Edit
								</button>
								<BsThreeDotsVertical size={20} />
							</div>
						</div>
					</div>
				</Container>
			</div>
		</Layout>
	);
}
