import React from "react";
import Marquee from "react-fast-marquee";
import Container from "../../../UI/Container";

export default function Partners() {
  const imgArr = ["guardian.png", "thecable.png", "pulse.png", "pulse.png"];
  return (
    <div className="wrap bg-primary py-5">
      <Container>
        {/* <div className="header font-medium text-center text-black text-3xl mb-3">
          <h1>
            Our <span className="text-red-700">Partner</span> &{" "}
            <span className="text-red-700">Sponsors</span>
          </h1>
        </div> */}
        <Marquee>
          <div className="flex gap-10 items-center">
            {imgArr.map((i, index) => (
              <div className="wrap" key={index}>
                <img
                  src={`/assets/${i}`}
                  alt={i}
                  className=" w-[150px] lg:w-[200px] "
                />
              </div>
            ))}
          </div>
        </Marquee>
      </Container>
    </div>
  );
}
