import {Link} from "react-router-dom";

const loginOption =[{
  id:1,
  src:"/assets/apple-solid.png",
  alt:"apple-auth"
},{
  id:2,
  src:"/assets/google-icon.png",
  alt:"google-auth"
},{
  id:3,
  src:"/assets/x-icon.png",
  alt:"x-auth"
},]

const SignupForm = () => {
  return (
        <div className="flex mt-20 max-w-[500px] px-5">
            <form>
            <div className="flex flex-col gap-3 items-center p-5 rounded-lg bg-[#1D1D1D]">
              <img src="/assets/nftng-logo.png" width={48} height={48}/>
              <p className="font-bold">Welcome Back</p>
              <p className="text-[12px]">Sign in to continue your work or    <span className="text-[14px] font-bold cursor-pointer">Login?</span></p>
              <div className="flex flex-col md:flex-row gap-3 w-full">
              <input type="text" placeholder="Name" className="bg-black p-2 w-full text-[12px] rounded-lg"/>
              <input type="text" placeholder="Email address" className="bg-black p-2 w-full text-[12px] rounded-lg"/>
              </div>
              
              <input type="passsword" placeholder="Password" className="bg-black p-2 w-full text-[12px] rounded-lg"/>
              <button className="bg-[#A1D283] flex items-center justify-center gap-2  tracking-tighter text-black p-1 rounded-lg w-full">Sign up</button>
              <p>or</p>
              <div>
                <div className="flex justify-between gap-5">
                  {loginOption.map(({id,src,alt})=>(
                    <button key={id} className="bg-gray-800 tracking-tighter content-center shadow-sm px-5 py-1 rounded-lg">
                      <img src={src} alt={alt} width={20} height={20}/>
                  </button>
                  ))}

                </div>
              </div>
            </div>

            </form>
        </div>
  )
}

export default SignupForm