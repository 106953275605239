import apiRequest from "../../utils/api-request";

// const BASE_URL = "http://127.0.0.1:5000";

interface TicketInterface {
  firstname: string;
  lastname: string;
  email: string;
  quantity: number;
  type: "regular" | "table";
}
export const createTicket = async (
  ticketData: TicketInterface,
  payment?: string,
  hash?: string
) => {
  const response = await apiRequest(
    `/create-ticket?payment=${payment}&hash=${hash}`,
    "POST",
    ticketData
  );
  return response;
};

export const verifyPayment = async (reference: string) => {
  const response = await apiRequest(
    `/verify-payment?reference=${reference}`,
    "PUT"
  );
  return response;
};

export const getTicketStats = async (params = {}) => {
  const query = new URLSearchParams(params).toString();
  const response = await apiRequest(`/get-tickets?${query}`, "GET");
  console.log(response);
  return response.message;
};

export const getTicketSaleStats = async (params = {}) => {
  const query = new URLSearchParams(params).toString();
  const response = await apiRequest(`/stat?${query}`, "GET");
  console.log(response);
  return response.message;
};

export const verifyTicketId = async (ticket_id: string) => {
  const response = await apiRequest(`/get-ticket/${ticket_id}`, "GET");
  console.log(response);
  return response.message;
};
