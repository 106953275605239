import { FC, ReactNode } from "react";

interface StatsCardProps {
	value: string;
	icon: ReactNode;
	text: string;
}

const StatsCard: FC<StatsCardProps> = ({ icon, value, text }) => {
	return (
		<div className="card rounded-xl py-5 px-3 bg-secondary flex flex-col gap-3">
			<div className="flex gap-2 items-center text-xl font-bold text-yellow-400">
				{icon} <span className="text-white">{value}</span>
			</div>
			<div className="text text-white text-opacity-60 text-xs">
				<p>{text}</p>
			</div>
		</div>
	);
};

export default StatsCard;
