import React, { useState } from "react";
import HnF from "../../../UI/HnF";
import Container from "../../../UI/Container";
import BgGrad from "../../../UI/BgGrad";
import Marquee from "react-fast-marquee";
import TicketEventModal from "../../../UI/dialogs/TicketEventModal";

export default function Intro() {
  const imgArr = [
    "e1.jpg",
    "e2.jpg",
    "e3.jpg",
    "e4.jpg",
    "e5.jpg",
    "e6.jpg",
    "e7.jpg",
    "e8.jpg",
    "e9.jpg",
    "e10.jpg",
    "e11.jpg",
    "e12.jpg",
    "e13.jpg",
  ];

  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      <TicketEventModal open={open} setOpen={setOpen} />
      <div className="wrap text-white py-32 relative">
        <img
          src="/assets/v2/intro-line.png"
          alt="introline"
          className="absolute top-0 inset-0 mx-auto"
        />
        <Container>
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="wrap text-center">
              <div className="wrap text-primary text-lg">
                <span>The Annual NFT Industry Event</span>
              </div>

              <div className="wrap">
                <h1 className="font-bold text-3xl lg:text-5xl my-7">
                  Empowering Africa’s{" "}
                  <span className="text-primary">
                    Creative <br /> Future
                  </span>{" "}
                  with Blockchain
                </h1>
                <span className=" opacity-50 text-sm">
                  At Nftng, we harness the power of Blockchain to drive <br />{" "}
                  innovation, creativity, and opportunity across Africa.
                </span>
              </div>
            </div>

            <Marquee>
              <div className="flex gap-4 items-center mt-5 ">
                {imgArr.map((i, index) => (
                  <div className="wrap w-[100px] ml-3 h-[100px] relative bg-white overflow-hidden rounded-lg">
                    <img
                      src={`/assets/v2/${i}`}
                      alt={i}
                      className="w-full h-full absolute inset-0 object-cover"
                    />
                  </div>
                ))}
              </div>
            </Marquee>
            <div className="wrap text-center pt-16 mt-16 relative lg:w-[100%] w-full  bg- bg-opacity-5 ">
              <img
                src="/assets/v2/intro-semi.png"
                alt="intro-semi"
                className="absolute inset-0 mx-auto"
              />
              <div className="relative z-10">
                <span>
                  Join NFTng and be part of an insightful <br /> knowledge about
                  Africa web3 space
                </span>{" "}
                <br />
                <button className="rounded-3xl p-2 text-xs bg-primary text-black min-w-[100px] font-medium mx-auto mt-4">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
