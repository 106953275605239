import React from "react";
import Layout from "../Layout";
import StatsCard from "./StatsCard";
import { GoGift } from "react-icons/go";
import Container from "../../../UI/Container";
import { SlTrophy } from "react-icons/sl";

export default function Referral() {
	const statsData = [
		{ value: "50", icon: <GoGift />, text: "Total Point" },
		{ value: "50", icon: <GoGift />, text: "Total Point" },
		{ value: "50", icon: <GoGift />, text: "Total Point" },
		{ value: "50", icon: <GoGift />, text: "Total Point" },
	];
	return (
		<Layout>
			<div className="wrap">
				{/*  */}
				<div className="Ads Section h-[150px] overflow-hidden flex justify-center items-center">
					<img src="/assets/dsp.jpeg" alt="NFTN" />
				</div>

				<div className="wrap bg-secondary-100 py-5">
					<Container>
						<div className="grid grid-cols-4 gap-10">
							{statsData.map((i, index) => (
								<StatsCard
									text={i.text}
									value={i.value}
									icon={i.icon}
								/>
							))}
						</div>

						<div className="table-wrap overflow-x-scroll custom-scrollbar">
							<table className="w-[100%] bg-secondary rounded-xl overflow-clip mt-10">
								<thead>
									<tr className="bg-primary text-black font-bold">
										<td>Username</td>
										<td>Refered</td>
										<td>Rank</td>
										<td>Point</td>
									</tr>
								</thead>
								<tbody>
									{statsData.map((i, index) => (
										<tr
											className="text-gray-400 text-sm"
										key={index}>
											<td>SHeezey Money</td>
											<td>20 Jul 2021</td>
											<td className="flex items-center gap-2">
												102
												<SlTrophy className="text-yellow-400" />
											</td>
											<td>15,000</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</Container>
				</div>
			</div>
		</Layout>
	);
}
