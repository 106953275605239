import React from "react";
import Container from "../../../UI/Container";

export default function Football() {
  const imgArr = ["w1.png", "w2.png", "w3.png"];
  return (
    <div className="wrap py-32 relative">
      <Container>
        <img
          src="/assets/v2/blur2.png"
          alt="half-circle"
          className="absolute left-0 inset-0 my-auto"
        />

        <div className="grid md:grid-cols-2 gap-16 items-center relative z-10">
          <div className="wrap text-white text-center lg:text-left">
            <h1 className="text-4xl uppercase font-medium">
              Our last <span className="text-primary">Event</span> and raffle
              winners
            </h1>
            <p className="opacity-60 mt-4">
              DeFi Summer 2024 was a dynamic event featuring a football
              tournament, an orphanage visitation, a DeFi conference, and a
              dinner party.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-3">
            {imgArr.map((i, index) => (
              <div
                className="img-wrap h-[200px] rounded-xl overflow-hidden"
                key={index}
              >
                <img
                  src={`/assets/${i}`}
                  alt="Winners"
                  className=" w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
