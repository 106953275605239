import React from "react";
import TeamCard from "../About/TeamCards";
import Container from "../../../UI/Container";
import Marquee from "react-fast-marquee";

export default function Team() {
  const teamFunc = (
    img: string,
    name: string,
    role: string,
    twitter: string,
    discord: string
  ) => ({
    img,
    name,
    role,
    twitter,
    discord,
  });

  const teamArr1 = [
    teamFunc("/team/teddi.JPG", "Teddi / King.sol", "Founder", "#", "#"),
    teamFunc("/team/someh.JPG", "Someh Crypto", "Co-Founder", "#", "#"),
    teamFunc("/team/juju_girl.JPG", "Juju Girl", "Creative Lead", "#", "#"),
    teamFunc("/team/jj.jpeg", "Joe", "Co Founder", "#", "#"),
    teamFunc("/team/ama.JPG", "Ama Crypt", " Marketing Lead", "#", "#"),
    teamFunc(
      "/team/sheezey.jpeg",
      "Sheezey",
      "Website Engineer & Blockchain dev",
      "https://twitter.com/sheezey_",
      "#"
    ),
    teamFunc(
      "/team/jemima.JPG",
      "Jemima Damina",
      "Marketing and PR Strategist",
      "#",
      "#"
    ),
    teamFunc("/team/swagnito.JPG", "Swagnito", "Event Coordinator", "#", "#"),
    teamFunc("/team/fiona.JPG", "Fiona", "SMM", "#", "#"),
  ];

  const teamArr2 = [
    teamFunc("/team/princess.jpg", "Princess Eno", "Team Member", "#", "#"),
    teamFunc("/team/frimtee.jpeg", "Frimtee", "Team Member", "#", "#"),
    teamFunc("/team/demitchy.JPG", "Demitchy", "Team Member", "#", "#"),
    teamFunc("/team/drogo.JPG", "Drogo", "Team Member", "#", "#"),
    teamFunc("/team/tokyo.JPG", "Tokyo", "Team Member", "#", "#"),
    teamFunc("/team/off.jpg", "Off Shoda", "Team Member", "#", "#"),
    teamFunc("/team/tubby.jpg", "Rex Vickz", "Blockkchain Dev", "#", "#"),
    teamFunc("/team/harry_media.JPG", "Harry Media", "Team Member", "#", "#"),
    teamFunc("/team/deb.png", "Deborah Ifegwu", "Project Management", "#", "#"),
  ];

  return (
    <div className="wrap py-32">
      <Container>
        <div className="text-center text-white mb-10 relative">
          <img
            src="/assets/v2/half-circle.png"
            alt="half-circle"
            className="absolute right-0 top-0 hidden lg:block"
          />
          <h1 className="text-3xl lg:text-5xl">
            Meet the <span className="text-primary">Team</span>
          </h1>
          <p className="text-xs opacity-50 lg:w-[50%] mx-auto">
            Our team at Nftng is a dedicated group of Web3 pioneers and
            community-builders, united by a mission to empower Africa’s creative
            talent. We bring expertise and passion to drive Blockchain
            innovation and opportunity across the continent.
          </p>
        </div>

        {/* <div className="grid grid-cols-6 gap-4">
          {teamArr.map((i, index) => (
            <TeamCard
              imgLink={`/assets${i.img}`}
              name={i.name}
              position={i.role}
              twitter={i.twitter}
              key={index}
            />
          ))}
        </div> */}
        <Marquee className="hidden lg:block">
          <div className="gap-4 hidden lg:flex">
            {teamArr1.map((i, index) => (
              <div className="w-[200px] mr-2">
                <TeamCard
                  imgLink={`/assets${i.img}`}
                  name={i.name}
                  position={i.role}
                  twitter={i.twitter}
                  key={index}
                />
              </div>
            ))}
          </div>
        </Marquee>
        <Marquee direction="right" className="mt-8 hidden lg:block">
          <div className=" gap-4 hidden lg:flex">
            {teamArr2.map((i, index) => (
              <div className="w-[200px] mr-2">
                <TeamCard
                  imgLink={`/assets${i.img}`}
                  name={i.name}
                  position={i.role}
                  twitter={i.twitter}
                  key={index}
                />
              </div>
            ))}
          </div>
        </Marquee>

        <div className="md:hidden relative overflow-x-auto">
          <div className="flex gap-4 whitespace-nowrap animate-marquee">
            {/* Map over teamArr1 and duplicate it to create the continuous effect */}
            {[...teamArr1, ...teamArr1, ...teamArr1, ...teamArr1].map(
              (i, index) => (
                <div className="w-[200px] flex-shrink-0 mr-4" key={index}>
                  <TeamCard
                    imgLink={`/assets${i.img}`}
                    name={i.name}
                    position={i.role}
                    twitter={i.twitter}
                  />
                </div>
              )
            )}
          </div>
        </div>

        <div className="md:hidden relative overflow-x-auto mt-5">
          <div className="flex gap-4 whitespace-nowrap animate-marquee">
            {/* Map over teamArr1 and duplicate it to create the continuous effect */}
            {[...teamArr2, ...teamArr2, ...teamArr2, ...teamArr2].map(
              (i, index) => (
                <div className="w-[200px] flex-shrink-0 mr-4" key={index}>
                  <TeamCard
                    imgLink={`/assets${i.img}`}
                    name={i.name}
                    position={i.role}
                    twitter={i.twitter}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
