import LoginForm from "../Components/LoginForm";
import SignupForm from "../Components/SignupForm";
//import {SignupForm} from "../Components/signupForm";


const Auth = () => {
  return (
    <div className="text-white flex items-center justify-center p-20">
      <SignupForm/>
    </div>
  )
}

export default Auth