import React from 'react';

interface ImageProps {
  img: string;
}

interface EventBoxProps {
  year: string;
  event_title: string;
  img: ImageProps[];
  link: string;
}

const EventBox: React.FC<EventBoxProps> = ({ year, event_title, img, link }) => {
  return (
    <div className="wrap py-16">
      <h2 className="text-white text-2xl mb-4"><span className='text-primary font-bold'>{year}</span> - {event_title} </h2>
      <div className="grid grid-cols-3 gap-4 bg-secondary-100 p-5 rounded-3xl">
        {img.map((image, index) => (
          <div 
            key={index} 
            className={`col-span-${index === 0 ? '3 md:col-span-2 lg:col-span-2' : '3 md:col-span-1 lg:col-span-1'} relative rounded-xl overflow-clip`}
            style={{ height: index === 0 ? '300px' : '150px' }}
          >
            <img src={`/assets/events/${image.img}`} alt={`Event ${index}`} className="w-full h-full object-cover" />
          </div>
        ))}
        <div className="col-span-3 md:col-span-1 lg:col-span-1">
          <a href={link} target='_blank' className="bg-primary text-black py-2 px-4 rounded-lg inline-block text-center">See More</a>
        </div>
      </div>
    </div>
  );
};

export default EventBox;
