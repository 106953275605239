import React from "react";
import Layout from "../Layout";
import Container from "../../../UI/Container";
import { MdOutlineSend } from "react-icons/md";
import TaskCard from "./TaskCard";

export default function Reward() {
	const dum = [1, 2, 3, 4, 4];
	return (
		<Layout>
			<div className="wrap">
				<div className="task-wrap bg-secondary-100 py-10 rounded-xl">
					<Container>
						{/* Primary Task */}
						<div className="header b1 px-4 py-10 flex justify-between items-center rounded-t-3xl">
							<div className="text-wrap text-white text-opacity-50">
								<h1 className="text-white font-bold text-3xl">
									Primary Task
								</h1>
								<span className="text-wrap text-white text-opacity-50">
									This Task Doesnt expire
								</span>
							</div>
						</div>
						<div className="overflow-x-scroll md:overflow-none custom-scrollbar">
							<div className="flex lg:grid lg:grid-cols-3 gap-10 p-3">
								{dum.map((i, index) => (
									<TaskCard />
								))}
							</div>
						</div>

						{/* Daily Task */}
						<div className="wrap mt-16">
							<div className="header b1 px-4 py-10 flex justify-between items-center rounded-t-3xl">
								<div className="text-wrap text-white text-opacity-50">
									<h1 className="text-white font-bold text-3xl">
										Complete Task
									</h1>
									<p>Claim point after task is completed</p>
								</div>
								<span className="text-wrap text-white text-opacity-50">
									Each task ends in 24 hrs
								</span>
							</div>

							<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 p-3">
								{dum.map((i, index) => (
									<TaskCard />
								))}
							</div>
						</div>

						{/* Special Task */}
						<div className="wrap mt-16">
							<div className="header b2 px-4 py-10 flex flex-wrap justify-between items-center rounded-t-3xl">
								<div className="text-wrap text-white text-opacity-50">
									<h1 className="text-white font-bold text-3xl">
										Special Task
									</h1>

								</div>
							</div>

							<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 p-3">
								{dum.map((i, index) => (
									<TaskCard />
								))}
							</div>
						</div>
					</Container>
				</div>
			</div>
		</Layout>
	);
}
