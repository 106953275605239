import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";

export interface MenuDataItem {
	link: string;
	icon: ReactNode;
	name?: string;
}

export interface SidebarProps {
	menuData: MenuDataItem[];
}

const Sidebar: FC<SidebarProps> = ({ menuData }) => {
	return (
		<div className="sidebar hidden lg:flex flex-col gap-10 text-2xl items-center text-white bg-secondary-100 p-5 rounded-xl py-10">
			{menuData.map((i, index) => (
				<NavLink
					key={index}
					to={i.link}
					className={({ isActive }) =>
						isActive
							? "pl-3 border-l-[6px] border-primary text-primary"
							: "pl-3 border-l-[6px] border-transparent text-white"
					}>
					{i.icon}
				</NavLink>
			))}
		</div>
	);
};

export default Sidebar;
