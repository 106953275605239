import React from "react";
import Container from "../../../UI/Container";
import { FaCircle, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import Item from "./Item";

interface CartProps {
	name?: string;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Cart: React.FC<CartProps> = ({ setShow }) => {


    // Close Dialog
	const handleBackgroundClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	return (
		<div
			className="wrap fixed backdrop-blur-xl z-[10] top-0 bottom-0 left-0 right-0 overflow-y-scroll pb-5 custom-scrollbar"
			onClick={handleBackgroundClick}>
			<Container>
				<div className="wrap lg:w-[80%] rounded-xl bg-neutral-800 relative mx-auto mt-[100px] p-10 overflow-hidden">
					<img
						src="/assets/icons/fadeT.png"
						alt="Circle"
						className="absolute top-0 right-0"
					/>
					<img
						src="/assets/icons/fadeB.png"
						alt="Circle"
						className="absolute bottom-0 left-0"
					/>

					<div className="header flex items-center justify-between relative z-[10] border-b border-b-gray-300 pb-5">
						<h1 className="text-3xl text-white font-bold">Cart</h1>
						<span className=" bg-neutral-800 flex px-3 py-2 gap-2 rounded-xl text-white text-sm">
							<FaTrashAlt /> Remove all
						</span>
					</div>

					<Item />
				</div>
			</Container>
		</div>
	);
};

export default Cart;
