import React, { FC } from "react";
import { MdCancel, MdCheckBox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

interface LoaderInterface {
  verify: boolean | "close" | "false";
}

const TicketPaymentLoader: FC<LoaderInterface> = ({ verify }) => {
  const navigate = useNavigate();
  if (!verify) return null;

  return (
    <div className="wrap fixed w-full h-full top-0 bottom-0 right-0 backdrop-blur-lg flex justify-center items-center z-[400] bg-black bg-opacity-50">
      {verify === "false" && (
        <div className="text-xl flex flex-col gap-5 justify-center items-center">
          <ClipLoader color="#a1d283" className="mx-auto" />
          <p className="text-white">Verifying Payment...</p>
        </div>
      )}

      {verify === true && (
        <div className="text-xl flex flex-col gap-3 justify-center items-center text-center">
          <div className="wrap h-[100px] w-[100px] bg-primary rounded-lg flex justify-center items-center">
            <MdCheckBox size={80} />
          </div>
          <p className="text-white text-sm lg:text-lg px-5">
            Yay! Ticket Purchase Successfully. <br /> Kindly check your email
            for your ticket
          </p>
          <button
            className="bg-primary text-black text-sm p-2 rounded-lg"
            onClick={() => navigate("/")}
          >
            Done
          </button>
        </div>
      )}

      {verify === "close" && (
        <div className="text-xl flex flex-col gap-3 justify-center items-center text-center">
          <div className="wrap h-[100px] w-[100px] text-white bg-red-500 bg-opacity-80 rounded-lg flex justify-center items-center">
            <MdCancel size={80} />
          </div>
          <p className="text-white text-sm lg:text-lg px-5">
            Invalid Payment Reference. <br /> Please Contact Us on Twitter for
            more info
          </p>
          <button
            className="bg-primary text-black text-sm p-2 rounded-lg"
            onClick={() => navigate("/")}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default TicketPaymentLoader;
