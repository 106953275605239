import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

export default function UserMbHeader() {
	const [toggle, setToggle] = useState(false);
	const menuFunc = (link: string, title: string) => {
		return { link, title };
	};
	const menuArr = [
		menuFunc("/", "HOME"),
		menuFunc("/apply", "APPLY"),
		menuFunc("/about", "ABOUT US"),
		menuFunc("/product", "PRODUCT"),
		menuFunc("/event", "EVENT"),
		menuFunc("/contact", "CONTACT US"),
	];
	return (
		<>
			<div className="logo-con flex justify-between py-8 bg-black px-4 sticky top-0 lg:hidden">
				<div className="img-wrap">
					<img src="/assets/nftng-logo.png" alt="NFTng" className="w-[100px]" />
				</div>
				<span
					className="flex text-white items-center gap-2 text-2xl cursor-pointer"
					onClick={() => setToggle(!toggle)}>
					<FaBars />
				</span>
			</div>

			<div
				className={`mb-header transform overflow-auto bg-black bottom-0 fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${
					toggle ? "translate-x-0" : "translate-x-full"
				} lg:hidden`}>
				<div className="container mx-auto">
					<div className="logo-con flex justify-between py-5 bg-black px-4 mb-">
						<div className="img-wrap">
							<img
								src="/assets/nftng-logo.png"
								alt="NFTng"
								className="w-[50px]"
							/>
						</div>
						<span
							className="flex text-primary items-center gap-2 cursor-pointer"
							onClick={() => setToggle(!toggle)}>
							close <FaCircleXmark />
						</span>
					</div>
					<div className="menu-link px-3 text-white">
						<ul>
							{menuArr.map((menu, index) => (
								<li
									key={index}
									className="flex my-3 text-center"
									onClick={() => setToggle(!toggle)}>
									<NavLink
										to={menu.link}
										className="bg-secondary py-3 w-full block">
										{menu.title}
									</NavLink>
								</li>
							))}
						</ul>
						<div className="rights text-center w-full bottom-0 text-xs text-white py-4">
							<span>Copyright © 2022 NFT Nigeria</span> <br />
							<span>Powered by NFTNG</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
