import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ApplyCardProps {
	title: string;
	text: string;
	icon: ReactNode;
	link: string;
	btnName: string;
}

const ApplyCard: FC<ApplyCardProps> = ({
	title,
	text,
	icon,
	link,
	btnName,
}) => {
	return (
		<div className="card p-4 rounded-2xl bg-secondary-100">
			<div className="header flex items-center gap-2 text-xl text-primary mb-3">
				{icon}
				<h1 className="text-white font-semibold">{title}</h1>
			</div>
			<div className="text-wrap text-gray-300 my-3 text-sm">
				<p>{text}</p>
			</div>
			<Link to={link}>
				<button className="bg-primary px-10 py-2 rounded-lg">
					{btnName}
				</button>{" "}
			</Link>
		</div>
	);
};

export default ApplyCard;
