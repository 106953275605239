import React from "react";
import Container from "../../../UI/Container";
import { FaCircle, FaMinus, FaPlus } from "react-icons/fa";

interface ProductDetailProps {
	name?: string;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ setShow }) => {

	// Close Dialog
	const handleBackgroundClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	return (
		<div
			className="wrap fixed backdrop-blur-xl z-[10] top-0 bottom-0 left-0 right-0 overflow-y-scroll pb-5 custom-scrollbar"
			onClick={handleBackgroundClick}>
			<Container>
				<div className="wrap lg:w-[80%] rounded-xl bg-neutral-800 relative mx-auto mt-[100px] p-10">
                    <img src="/assets/icons/fadeT.png" alt="Circle" className="absolute top-0 right-0" />
                    <img src="/assets/icons/fadeB.png" alt="Circle" className="absolute bottom-0 left-0" />
					<div className="md:flex items-center gap-10">
						<div className="img-wrap">
							<img
								src="/assets/product/croctop.png"
								alt="Croctop"
								className="w-[500px] relative z-20"
							/>
						</div>

						<div className="wrap relative z-20">
							<div className="header">
								<h1 className="text-4xl font-bold text-white">
									Defi Summer Croppy
								</h1>
								<div className="wrap flex gap-2 items-center text-gray-200 font-thin text-sm">
									<p>#34,0000</p>
									<p>| 2.3k Reviews |</p>
									<p>1.6k SOld</p>
								</div>
							</div>

							<div className="wrap mt-5">
								<div className="flex gap-3">
									<div className="wrap">
										<p className="font-bold text-white text-sm">
											Colour
										</p>
										<div className="color flex gap-2 mt-2">
											<FaCircle className="text-green-500" />
											<FaCircle className="text-black" />
										</div>
									</div>

									<div className="wrap text-white ">
										<p className="font-bold text-sm">
											Sizes
										</p>
										<div className="text-wrap text-xs font-thin mt-2">
											<p>M, L, XL, XXL</p>
										</div>
									</div>
								</div>
							</div>

							<div className="text-wrap my-5">
								<h1 className="font-bold text-white mb-2 text-lg">
									Description
								</h1>
								<span className="text-sm text-gray-400">
									Lorem ipsum dolor sit amet consectetur
									adipisicing elit. Quis deleniti corrupti quo
									vitae iusto dolorem sed impedit possimus
									asperiores perferendis ut, illo tempore
									fugit deserunt voluptates alias velit
									consectetur facere!
								</span>
							</div>

							<div className="wrap flex items-center gap-3 mb-5">
								<span className="text-lg font-bold text-white">
									Quantity
								</span>
								<div className="flex items-center text-sm gap-2 font-bold bg-black p-2 rounded-md text-white">
									<span className="border border-dotted p-1">
										<FaPlus />
									</span>{" "}
									<input
										type="text"
										className="w-[50px] bg-transparent text-center"
										value={1}
									/>
									<span className="border border-dotted p-1">
										<FaMinus />
									</span>
								</div>
							</div>

							<div className="btn-wrap flex gap-4">
								<a href="#">
									<button className="text-slate-100 rounded-xl border border-slate-300 px-4 p-2">
										Add to cart
									</button>
								</a>
								<a href="#">
									<button className="text-gray-200 bg-neutral-700 rounded-xl border border-neutral-700 px-4 p-2">
										View cart
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default ProductDetail;
