import React from "react";
import Container from "../../../UI/Container";

export default function AbtIntro() {
  return (
    <div className="wrap text-white py-32 relative">
      <img
        src="/assets/v2/semi-c2.png"
        alt="introline"
        className="absolute bottom-0 right-0"
      />
      <Container>
        <div className="flex flex-col justify-center items-center gap-4 relative z-10">
          <div className="wrap text-center">
            <div className="wrap">
              <h1 className="font-medium text-3xl lg:text-5xl my-7">
                We bring a wealth of skills and Experience <br /> from a wide
                range of background
              </h1>
              <span className=" opacity-50 text-sm">
                We hire grate people and give them the best resources and
                support to do grate work.
              </span>
            </div>
          </div>
          <div className="wrap text-center pt-16 mt-16 relative lg:w-[100%] w-full  bg- bg-opacity-5 ">
            <img
              src="/assets/v2/intro-semi.png"
              alt="intro-semi"
              className="absolute inset-0 mx-auto"
            />
            <div className="relative z-10">
              <span>
                Join NFTng and be part of an insightful <br /> knowledge about
                africa web3 space
              </span>{" "}
              <br />
              <button className="rounded-3xl p-2 text-xs bg-primary text-black min-w-[100px] font-medium mx-auto mt-4">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

// table booking and
// 200 early bed
// 900 early bed
