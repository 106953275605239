import React from "react";
import Dropdown from "../Contact/Dropdown";
import Container from "../../../UI/Container";

export default function Faq() {
  const faqFunc = (title: string, text: string) => {
    return { title, text };
  };
  const faqArr = [
    faqFunc(
      "What is the NFT_NG Pass?",
      "The NFTNG Pass is a NFT Pass that gives holders exclusive lifetime access to all NFTNG events and products. "
    ),
    faqFunc(
      "  What is NFT_NG Vision?  ",
      "Bringing together digital creators, developers, thought leaders and other Web3 enthusiasts in the African Space and empowering them with opportunities to thrive on a global scale."
    ),
    faqFunc(
      " What utilities does the NFTNG pass have? ",
      "The NFTNG pass gives holders exclusive access to the DEFI Summer event as well as a discount on the DEFI Summer Merchandise. Also, the pass grants holders a lifetime access to other NFTNG products and events. "
    ),
    faqFunc(
      " What is the date and venue of the DEFI Summer Event? ",
      "The DEFI Summer event will hold on the 26th of June  2024 in Lagos, Nigeria. "
    ),
  ];

  return (
    <Container>
      <div className="py-10 grid md:grid-cols-2 gap-16 relative overflow-hidden">
        <img
          src="/assets/v2/v-circle.png"
          alt="circle"
          className=" absolute inset-0 m-auto"
        />
        <div className="wrap relative z-10">
          <div className="head py-4">
            <h1 className="font-poppins text-ng-primary text-3xl text-white">
              Frequently asked <br /> Questions
            </h1>
          </div>

          <div className="items-center">
            {faqArr.map((i, index) => (
              <Dropdown text={i.text} title={i.title} key={index} />
            ))}
          </div>
        </div>

        <div className="wrap relative z-10">
          <form>
            <div className="header mb-5">
              <h1 className="text-white text-3xl">Get In Touch</h1>
              <span className="text-primary text-sm">
                24/7 we’ll answer your questions and problem
              </span>
            </div>

            <div className="grid grid-cols-2 gap-5">
              <input
                type="text"
                className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                placeholder="Name"
              />
              <input
                type="email"
                className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                placeholder="Email"
              />
              <input
                type="tel"
                className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                placeholder="Phone"
              />
              <input
                type="text"
                className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                placeholder="Twitter Handle"
              />
              <textarea
                className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent col-span-2"
                rows={5}
                placeholder="Type your message"
              />
              <div className="wrap">
                <button className="text-black bg-primary rounded-xl px-4 p-2">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}
