import React from "react";
import HnF from "../../../UI/HnF";
import Container from "../../../UI/Container";
import ApplyCard from "./ApplyCard";
import { SiGithubsponsors } from "react-icons/si";
import {
	FaHome,
	FaUser,
	FaCog,
	FaSignOutAlt,
	FaInfoCircle,
	FaEnvelope,
	FaMicrophoneAlt,
	FaAward,
} from "react-icons/fa";
import Speaker from "./Speakers";
import { GoPeople } from "react-icons/go";
import { RiShakeHandsLine } from "react-icons/ri";

export default function Apply() {
	const cardData = [
		{
			title: "AWARD",
			icon: <FaAward />,
			text: "DEFI Summer event will include an award session for reputable Web3 figures. Awardees must have shown evidence of driving ideas that are good for the Web3 space, making an impact in the web3 community, or building/contributing to impactful web3 projects. To nominate yourself or someone who fits into the criteria as an awardee, fill this form.",
			link: "/home",
			btnName: "Be a Nominee",
		},
		{
			title: "SPEAKER",
			icon: <FaMicrophoneAlt />,
			text: "DEFI Summer event (and other NFTNG events) will entail informative sessions from seasoned speakers. Prospective speakers must have shown expertise in the respective aspects of Web3 (including NFTs, DeFi, Gaming, Metaverse, Digital Art, Mining, Venture Capitalism) they will be speaking on. To nominate yourself or someone who fits into the criteria as a speaker, fill this form.",
			link: "/profile",
			btnName: "Be a Speaker",
		},
		{
			title: "VOLUNTEER",
			icon: <GoPeople />,
			text: "NFTNG volunteer service program is geared towards providing skillful Web3 individuals with a platform to sharpen their skills and gain positive connections. Volunteers must be skillful in any of protocol/ushering, media (photography, graphics & animations, others), technical management, content creation, or public relations, and be willing to contribute to NFTNG. If you would like to volunteer for NFTNG, kindly fill this form",
			link: "/settings",
			btnName: "Appp",
		},
		{
			title: "SPONSOR",
			icon: <SiGithubsponsors />,
			text: "NFTNG is currently seeking sponsors for its upcoming DEFI Summer event, as well as for the development and implementation of its various utilities. If you're interested in sponsoring DEFI Summer event or any of NFTNG's events or products, we invite you to become a sponsor today.",
			link: "/logout",
			btnName: "Appp",
		},
		{
			title: "PARTNER",
			icon: <RiShakeHandsLine />,
			text: "NFTNG is looking for partners to form positive alliances in achieving its mission. f you're interested in sponsoring DEFI Summer event or any of NFTNG's events or products, we invite you to become a Partner today.",
			link: "/about",
			btnName: "Appp",
		},
	];

	const speakers = [
		{
			name: "John Doe",
			role: "Frontend Developer",
			text: "John has over 5 years of experience in developing responsive and dynamic web applications.",
			img: "/assets/team/someh.png",
		},
		{
			name: "Jane Smith",
			role: "Backend Developer",
			text: "Jane specializes in server-side development and has a strong background in database management.",
			img: "/assets/team/someh.png",
		},
		{
			name: "Alice Johnson",
			role: "UI/UX Designer",
			text: "Alice is passionate about creating user-friendly and aesthetically pleasing designs.",
			img: "/assets/team/someh.png",
		},
	];

	return (
		<HnF>
			<div className="wrap">
				<Container>
					<div className="grid gap-10 lg:grid-cols-2 items-center py-10">
						<div className="text-wrap">
							<h1 className="text-3xl text-white font-bold">
								Be a member of Nftng web3 space and Community
							</h1>
							<p className="text-gray-400 my-5">
								Lorem ipsum dolor sit amet consectetur
								adipisicing elit. Explicabo earum omnis
								molestias eaque esse quod, accusantium harum
								quas perferendis pariatur veritatis vitae
								maiores eligendi, praesentium consequatur,
								consequuntur sapiente ipsa neque!
							</p>
							<button className="bg-primary text-black rounded-xl px-4 py-2">
								Apply Today
							</button>
						</div>
						<div className="img-wrap">
							<img
								src="/assets/nftng2.png"
								alt="NFTNG"
								className="rounded-xl float-end"
							/>
						</div>
					</div>
				</Container>
				<div className="bg-black py-16">
					<div className="header text-white font-bold text-3xl text-center mb-5">
						<h1>
							Choose{" "}
							<span className="text-primary">
								Application Apply
							</span>
						</h1>
					</div>
					<Container>
						<div className="grid grid-cols-2 lg:grid-cols-3 gap-10">
							{cardData.map((i, index) => (
								<ApplyCard
									title={i.title}
									text={i.text}
									icon={i.icon}
									link={i.link}
									btnName={i.btnName}
									key={index}
								/>
							))}
						</div>
					</Container>
				</div>

				<div className="py-16">
					<Container>
						<div className="header text-white font-bold text-xl mb-5">
							<h1>
								Previous Speakers{" "}
								<span className="text-primary">
									and Awardee
								</span>
							</h1>
						</div>
						<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10">
							{speakers.map((i, index) => (
								<Speaker
									name={i.name}
									role={i.role}
									img={i.img}
									text={i.text}
									key={index}
								/>
							))}
						</div>
					</Container>
				</div>
			</div>
		</HnF>
	);
}
