interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="z-30 content-center backdrop-blur-sm fixed top-0 bottom-0 right-0 left-0" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      
      <div className="bg-tertiary max-w-[600px] max-h-[600px] mx-auto rounded-xl  py-4">
        {content}
        <button className="bg-primary text-black font-medium rounded-xl mx-auto px-3 py-1 w-96 tracking-tighter justify-center items-center content-center flex" onClick={onClose}>Confirmed</button>
      </div>
    </div>
  );
};

export default Modal;
