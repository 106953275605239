import React from "react";
import Container from "../../../UI/Container";

export default function Abt() {
  return (
    <div className="wrap py-16">
      <Container>
        <div className="flex flex-col-reverse gap-10 md:grid md:grid-cols-2 bg-secondary p-5 py-10 overflow-hidden rounded-xl relative">
          <img
            src="/assets/v2/half-circle.png"
            alt="half-circle"
            className="hidden md:block absolute right-0 top-0 w-[100px]"
          />
          <img
            src="/assets/v2/half-circle.png"
            alt="half-circle"
            className="absolute left-0 bottom-0 rotate-[180deg] w-[100px] hidden lg:block"
          />
          <img
            src="/assets/v2/v-circle.png"
            alt="circle"
            className=" absolute inset-0 m-auto"
          />
          <div className="wrap relative z-10 ">
            <h1 className="text-4xl font-bold mb-5 text-center lg:text-left">
              About <span className="text-primary">NFTng</span>
            </h1>
            <span className="text-sm">
              NFTNG harnesses Blockchain to ignite creativity and innovation
              across Africa, spotlighting the talent of the Web3 African
              community. <br /> <br /> We provide education, resources, and a
              platform for African creatives to thrive, collaborate, and lead.
              Our mission is to drive Web3 adoption and showcase Africa’s
              potential on the global stage.
            </span>
          </div>
          <div className="img-wrap flex items-center justify-center">
            <img
              src="/assets/nftng-logo.png"
              alt="NFTNg"
              className="w-[200px] lg:w-[300px] relative z-10"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
