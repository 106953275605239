import { ReactNode } from "react";
import { MdOutlineDashboard, MdOutlineLeaderboard } from "react-icons/md";
import { AiOutlineUsergroupAdd, AiTwotoneShop } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { GoGift } from "react-icons/go";
import { FiUser } from "react-icons/fi";
import Sidebar, { MenuDataItem } from "./Sidebar";
import Submenu from "./SubMenu";
import HnF from "../../UI/HnF";
import UserMbHeader from "../../UI/UserMbHeader";

interface LayoutProps {
	children: ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
	const menuData: MenuDataItem[] = [
		{ link: "/user/dashboard", icon: <MdOutlineDashboard /> },
		{ link: "/user/leaderboard", icon: <MdOutlineLeaderboard /> },
		{ link: "/product", icon: <AiTwotoneShop /> },
		{ link: "/product", icon: <IoSettingsOutline /> },
	];

	const SideMenuData: MenuDataItem[] = [
		{ link: "/user/dashboard/profile", icon: <FiUser />, name: "Profile" },
		{
			link: "/user/dashboard/referrals",
			icon: <AiOutlineUsergroupAdd />,
			name: "Referrals",
		},
		{ link: "/user/dashboard/reward", icon: <GoGift />, name: "Reward" },
	];
	return (
		<>
			<div className="lg:fixed top-0 right-0 left-0 bottom-0 flex gap-10 lg:px-5 lg:py-10">
				<Sidebar menuData={menuData} />

				<Submenu menuData={SideMenuData} />

				<div className="wrap w-full rounded-xl overflow-y-scroll overflow-clip custom-scrollbar">
					<UserMbHeader />
					{children}
				</div>
			</div>
		</>
	);
};

export default Layout;
