import React from "react";
import "./App.css";
import { Route, Router, Routes } from "react-router-dom";
import About from "./Pages/Client/About/About";
import Product from "./Pages/Client/Product/Product";
import Contact from "./Pages/Client/Contact/Contact";
import Referral from "./Pages/User/Referral/Referral";
import Home from "./Pages/Client/Homepage/Home";
import Apply from "./Pages/Client/Apply/Apply";
import Events from "./Pages/Client/Homepage/Events";
import Reward from "./Pages/User/Reward/Reward";
import Profile from "./Pages/User/Profile/Profile";
import Ticket from "./Pages/User/Ticket/Ticket";
import Providers from "./contexts/providers";
import Auth from "./Pages/Admin/Auth/Auth";
import AdminLayout from "./Pages/Admin/AdminLayout";
import DashboardLayout from "./Pages/Admin/Dashboard/DashboardLayout";
import DashboardHome from "./Pages/Admin/Dashboard/DashboardHome";
import VerifyTicket from "./Pages/Admin/Dashboard/VerifyTicket";

function App() {
  return (
    <Providers>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/event" element={<Events />} />
        <Route path="/product" element={<Product />} />
        <Route path="/Contact" element={<Contact />} />

        {/* Admin Routes */}
        <Route path="/admin" element={<AdminLayout/>}>
        <Route path="auth" element={<Auth/>}/>

        <Route path="dashboard" element={<DashboardLayout/>}>
        <Route  index element={<DashboardHome/>}/>
        <Route path="verify-ticket" element={<VerifyTicket/>}/>
        </Route>
        </Route>

        {/* User Ruutes */}
        <Route path="/user/dashboard/reward" element={<Reward />} />
        <Route path="/user/dashboard/referrals" element={<Referral />} />
        <Route path="/user/dashboard/profile" element={<Profile />} />


        {/* Ticketting */}
        <Route path="/buy/ticket" element={<Ticket />} />
        <Route path="/buy/ticket/:reference" element={<Ticket />} />
      </Routes>

    </Providers>
  );
}

export default App;
