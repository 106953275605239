import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface SpeakerProps {
	name: string;
	role: string;
	text: string;
	img: string;
}

const Speaker: FC<SpeakerProps> = ({
	name,
	role,
	text,
	img,
}) => {
	return (
		<div className="card p-4 rounded-2xl bg-secondary-100">
			<div className="header flex items-center gap-3 text-xl text-primary mb-3">
				<img src={img} className="w-[50px] h-[50px] rounded-full " alt={name} />
				<div className="wrap">
				<h1 className="font-semibold">{name}</h1>
				<span className="text-sm text-white">{role}</span>
				</div>
			</div>
			<div className="text-wrap text-gray-300 my-3">
				<p>{text}</p>
			</div>
		</div>
	);
};

export default Speaker;
