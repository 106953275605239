import React, { useState } from "react";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";

interface DropdownProps {
  title: string;
  text: string;
}

const Dropdown: React.FC<DropdownProps> = ({ title, text }) => {
  const [toggle, setToggle] = useState(false);

  const faqArr = [
    "What is the NFTNG Pass?",
    "What is NFTNG Vision?",
    "What utilities does the NFTNG pass have?",
    "What is the date and venue?",
  ];

  return (
    <div className="wrap w-full py-2">
      <div className="flex mb-4 text-light justify-between px-3 p-2 items-center rounded-xl">
        <div className="wrap w-[100%]">
          <div className="flex text-sm justify-between items-center text-primary">
            <span>{title}</span>{" "}
            {toggle ? (
              <FaCirclePlus color="#fff" onClick={() => setToggle(false)} />
            ) : (
              <FaCircleMinus color="#fff" onClick={() => setToggle(true)} />
            )}
          </div>
          {toggle && (
            <div className="text-wrap text-sm bg-white bg-opacity-10 text-white text-opacity-80 p-3 rounded-lg mt-3">
              <span>{text}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
