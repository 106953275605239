import axios, { AxiosRequestConfig, Method } from "axios";
import { setCookie } from "./cookies";

interface Config extends AxiosRequestConfig {
  method: Method;
}
const BASE_URL = window.location.href.includes("localhost:3000")
  ? "http://127.0.0.1:5000"
  : "https://nftng-server-v2.onrender.com";

const apiRequest = async (
  url: string,
  method: Method = "GET",
  body: object | null = null
) => {
  const config: Config = {
    method,
    url: `${BASE_URL}${url}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  if (body) {
    config.data = body;
  }

  try {
    const response = await axios(config);

    const token = response.headers["auth-user-token"];
    if (token) {
      setCookie("auth-user-token", token);
      window.location.href = "/";
    }

    return response.data;
  } catch (error) {
    console.error("Error making request:", error);
    throw error;
  }
};

export default apiRequest;
