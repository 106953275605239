import React from "react";
import Container from "../../../UI/Container";

export default function Mission() {
  const cardData = [
    // {
    // 	title: "Web3",
    // 	text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
    // 	img: "/m1.png",
    // },
    {
      title: "Events",
      text: "We provide accessible Web3 education to bridge knowledge gaps and empower African creatives with the resources they need to thrive.",
      img: "/m2.png",
    },
    {
      title: "Partners",
      text: " At Nftng, we believe in the power of connection. Our community initiatives foster collaboration and support, creating a network of like-minded individuals passionate about Blockchain’s.",
      img: "/m3.png",
    },
    {
      title: "Partners",
      text: "We offer a platform for African creatives to shine on the global stage through events, partnerships, and online channels.",
      img: "/m4.png",
    },
  ];
  return (
    <div className="wrap pt-32">
      <Container>
        <div className="text-center text-white mb-10">
          <h1 className="text-3xl lg:text-5xl">
            Our <span className="text-primary">Mission</span>
          </h1>
          <span className="text-xs opacity-50">
            Our mission at Nftng is rooted in three core pillars that <br />{" "}
            empower African creatives in the Web3 space:
          </span>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 px-10">
          {cardData.map((i, index) => (
            <div
              className="card bg-secondary rounded-3xl p-5 flex flex-col justify-between"
              key={index}
            >
              <div className="img-wrap">
                <img
                  src={`/assets/${i.img}`}
                  alt="img"
                  className="w-[200px] mx-auto"
                />
              </div>
              <div className="flex">
                <span className="font-bold text-white bg-secondary-100 rounded-full h-[30px] w-[30px] flex justify-center items-center">
                  {index + 1}
                </span>
              </div>
              <p className="text-gray-300 text-sm opacity-70 ">{i.text}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
