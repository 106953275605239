import React from "react";
import { FaCircle } from "react-icons/fa";

interface ProductCardProps {
	price: string;
	name: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ name, price }) => {
	return (
		<div className="wrap rounded-xl overflow-hidden relative">
			<div className="header absolute flex justify-between items-center w-full p-1 lg:p-5">
				<img src="/assets/icons/heart-plus.png" alt="wishlist" />
				<span className="bg-primary text-black px-2 p-1 rounded-2xl font-semibold">
					#{price}
				</span>
			</div>
			<img src="/assets/product/totes.png" alt={name} />

			<div className="rounded-xl lg:p-3 lg:absolute bottom-1 w-full">
				<div className="wrap bg-white rounded-b-xl lg:rounded-xl p-3">
					<h1 className="font-semibold mb-3">{name}</h1>
					<div className="flex justify-between items-center">
						<div className="text-wrap text-xs font-thin">
							<p>Size M, L, XL, XXL</p>
						</div>
						<div className="color flex gap-2">
							<FaCircle className="text-green-500" />
							<FaCircle className="text-black" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
