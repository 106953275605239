import React, { useEffect, useState } from "react";
import { useConnect, useAccount, useWriteContract } from "wagmi";
import { injected } from "wagmi/connectors";
import { bsc } from "viem/chains";
import abi from "../contract-abi/USDT.json";

interface PayParams {
  setHash: (hash: string) => void;
  setPayment: (payment: string) => void;
  price: number;
}

export default function CryptoPayButton({
  setHash,
  setPayment,
  price,
}: PayParams) {
  const { connectAsync } = useConnect();
  const { address } = useAccount();
  const { writeContractAsync } = useWriteContract();
  const [started, setStarted] = useState(false);
  const [errors, setErrors] = useState<any>("");
  const [completed, setCompleted] = useState(false);

  const handlePayment = async () => {
    if (price === 0) return alert("Please select ticket amount");
    try {
      setErrors("");
      setStarted(true);
      if (!address) {
        await connectAsync({ chainId: bsc.id, connector: injected() });
      }

      // Using 'any' type assertion to bypass TypeScript error here
      const data = await (writeContractAsync as any)({
        chainId: bsc.id,
        address: "0x55d398326f99059fF775485246999027B3197955", // change to recipient address
        functionName: "transfer",
        abi,
        args: [
          "0x6d98217a6de828625da6a85c5cfb5fe68db22ba1",
          BigInt(price * 1000000),
        ],
      });
      setCompleted(true);

      setPayment("crypto");
      setHash(data);
      console.log(data);
    } catch (err) {
      console.log(err);
      setStarted(false);
      setErrors(
        <>
          <small>Error occured ensure you have</small>
          <ul className=" list-disc list-inside">
            <li>Switched to BSC network</li>
            <li>Have BNB for gas</li>
            <li>Have sufficient USDT (BEP20)</li>
          </ul>
        </>
      );
    }
  };

  useEffect(() => {}, [price]);

  return (
    <>
      {!completed && (
        <button
          disabled={started}
          type="submit"
          className="p-3 rounded-lg bg-white text-black text-sm w-full hover:bg-opacity-80 hover:text-white transition-all mt-3"
          onClick={handlePayment}
        >
          {started ? "Confirming..." : "Pay with crypto"}
        </button>
      )}
      {completed && (
        <p className="text-stone-800 mt-2 bg-green-200 rounded-md text-sm py-2 px-4 text-center">
          Thank you for your payment. Please check your email
        </p>
      )}
      {errors && (
        <p className="text-stone-800 mt-2 bg-red-200 rounded-md text-sm py-2 px-4">
          {errors}
        </p>
      )}
    </>
  );
}
