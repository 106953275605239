import React from "react";
import Container from "../../../UI/Container";

export default function CoreValue() {
  const coreCard = [
    <span>
      Empowerment <span className="text-primary">through</span> Education
    </span>,
    <span>
      Community-Driven <span className="text-primary">Collaboration</span>
    </span>,
    <span>
      Global Visibility <span className="text-primary">for African</span> Talent
    </span>,
  ];
  return (
    <div className="wrap bg-primary bg-opacity-60 py-10">
      <Container>
        <div className="header text-center text-3xl font-medium mb-5">
          <h1>
            Core <span className="text-secondary">Value</span>
          </h1>
        </div>
        <div className="grid grid-cols-2 sm:flex gap-3 justify-center lg:gap-10">
          {coreCard.map((i, index) => (
            <div
              className="box p-5 rounded-xl bg-secondary sm:w-[200px]"
              key={index}
            >
              <img
                src="/assets/v2/abt-core.png"
                alt="core"
                className="w-[100px] mx-auto"
              />
              {i}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
