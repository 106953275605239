import React from "react";
import HnF from "../../../UI/HnF";
import Container from "../../../UI/Container";
import EventBox from "./EventBox";
import Marquee from "react-fast-marquee";

export default function Events() {
  const imgArr = [
    { img: "e1.png" },
    { img: "e2.png" },
    { img: "e3.png" },
    { img: "e4.png" },
    { img: "e5.png" },
    { img: "e6.png" },
    { img: "e7.png" },
    { img: "e8.png" },
  ];
  return (
    <HnF>
      <div className="wrap">
        <div className="event-intro text-5xl lg:text-7xl font-extrabold">
          <div className="bg-con bg-black bg-opacity-60 backdrop-blur-sm">
            <Container>
              <div className="wrap text-white py-32">
                <h1>
                  EVENT, <br /> NFTNG{" "}
                  <span className="text-primary">CONFERENCE</span>
                </h1>
              </div>
            </Container>
          </div>
        </div>
        <Marquee className="bg-black">
          <div className="flex gap-5 text-primary font-bold bg-black py-3">
            {imgArr.map((i, index) => (
              <span key={index}>DEFI SUMMER 24'</span>
            ))}
            {imgArr.map((i, index) => (
              <span key={index}>DEFI SUMMER 24'</span>
            ))}
          </div>
        </Marquee>

        {/* Events Images Displays  */}
        <Container>
          <EventBox
            year="2024"
            event_title="DeFi Summer 2021"
            img={imgArr}
            link="#"
          />
        </Container>
      </div>
    </HnF>
  );
}
