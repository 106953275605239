import { ReactNode } from "react";
import { FiMessageCircle } from "react-icons/fi";

interface ContactCardProps {
	title: string;
	subtext: string;
	text: string;
	icon: ReactNode;
}
const ContactCard: React.FC<ContactCardProps> = ({ title, subtext, text, icon }) => {
	return (
		<div className="card bg-gradient p-5 rounded-xl">
			<div className="icon-header text-primary py-3">
				{icon}
			</div>
			<div className="flex flex-col gap-3 text-white mt-3">
				<h1 className="text-xl font-bold">{title}</h1>
				<span className="text-sm font-thin">{subtext}</span>
				<p className="text-md font-semibold">{text}</p>
			</div>
		</div>
	);
};

export default ContactCard;
