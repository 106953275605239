import AdminHeader from "./Components/AdminHeader";
import {Outlet, useLocation} from "react-router-dom";
import Header from "../../UI/Header";

const AdminLayout: React.FC = () => {
    const location = useLocation();
    
    const showHeader = location.pathname.includes('/auth');
  return (
    <div className="bg-secondary">
        {showHeader && <Header/>}
        <main className="">   
            <Outlet/>
        </main>
    </div>
  )
}

export default AdminLayout;