import React from "react";
import { FiMessageCircle } from "react-icons/fi";
import { MdOutlineSupportAgent } from "react-icons/md";
import HnF from "../../../UI/HnF";
import Container from "../../../UI/Container";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import ContactCard from "./ContactCard";
import { FaGlobe, FaPhone } from "react-icons/fa";

export default function Contact() {
  const faqFunc = (title: string, text: string) => {
    return { title, text };
  };
  const faqArr = [
    faqFunc(
      "What is the NFT_NG Pass?",
      "The NFTNG Pass is a NFT Pass that gives holders exclusive lifetime access to all NFTNG events and products. "
    ),
    faqFunc(
      "  What is NFT_NG Vision?  ",
      "Bringing together digital creators, developers, thought leaders and other Web3 enthusiasts in the African Space and empowering them with opportunities to thrive on a global scale."
    ),
    faqFunc(
      " What utilities does the NFTNG pass have? ",
      "The NFTNG pass gives holders exclusive access to the DEFI Summer event as well as a discount on the DEFI Summer Merchandise. Also, the pass grants holders a lifetime access to other NFTNG products and events. "
    ),
    faqFunc(
      " What is the date and venue of the DEFI Summer Event? ",
      "The DEFI Summer event will hold on the 26th of June  2024 in Lagos, Nigeria. "
    ),
  ];

  const cardArr = [
    {
      title: "Chat For Merch",
      subtext: "Speak to our salesman",
      text: "sales@nftng.io",
      icon: <FiMessageCircle size={30} />,
    },
    {
      title: "Chat For Support",
      subtext: "Speak to our customer service",
      text: "support@nftng.io",
      icon: <MdOutlineSupportAgent size={30} />,
    },
    {
      title: "Call Us Today",
      subtext: "Speak to our representative",
      text: "+1 584 543 0000",
      icon: <FaPhone size={30} />,
    },
    {
      title: "Stay up to date",
      subtext: "Speak to our salesman",
      text: "info@nftng.io",
      icon: <FaGlobe size={30} />,
    },
  ];

  return (
    <div className="wrap">
      <HnF>
        <Container>
          <div className="header text-center py-32">
            <h1 className="font-bold text-3xl text-white">
              Chat our friendly support team
            </h1>
            <p className="font-thin text-primary">
              let us know how we can help
            </p>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
            {cardArr.map((i, index) => (
              <ContactCard
                title={i.title}
                text={i.text}
                subtext={i.subtext}
                icon={i.icon}
              />
            ))}
          </div>

          {/* FAQ SEXTUIN */}
          <div className="py-10 grid md:grid-cols-2 gap-16 mt-16">
            <div className="wrap">
              <div className="head py-4">
                <h1 className="font-poppins text-ng-primary text-4xl font-bold text-white">
                  Frequently asked <br /> Questions
                </h1>
              </div>

              <div className="items-center">
                {faqArr.map((i, index) => (
                  <Dropdown text={i.text} title={i.title} key={index} />
                ))}
              </div>
            </div>

            <div className="wrap">
              <form>
                <div className="header mb-5">
                  <h1 className="text-white font-bold text-3xl">
                    Get In Touch
                  </h1>
                  <span className="text-primary">
                    24/7 we’ll answer your questions and problem
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-5">
                  <input
                    type="text"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                    placeholder="Name"
                  />
                  <input
                    type="email"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                    placeholder="Email"
                  />
                  <input
                    type="tel"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent"
                    placeholder="Twitter Handle"
                  />
                  <textarea
                    className="border border-white border-opacity-15 rounded-xl p-3 bg-transparent col-span-2"
                    rows={5}
                    placeholder="Type your message"
                  />
                  <div className="wrap">
                    <button className="text-black bg-primary rounded-xl px-4 p-2">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </HnF>
    </div>
  );
}
