import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../../UI/Container";
import HnF from "../../../UI/HnF";
import ProductCard from "./ProductCard";
import ProductDetail from "./ProductDetail";
import Cart from "../Cart/Cart";

export default function Product() {
	const dum = [1, 2, 3, 4, 5, 6];
	const [show, setShow] = useState<boolean>(false);
	const [show2, setShow2] = useState<boolean>(false);
	return (
		<div className="wrap">
			<HnF>
				{show && <ProductDetail setShow={setShow} />}
				{show2 && <Cart setShow={setShow2} />}
				<Container>
					<div className="header flex flex-wrap gap-3 justify-between items-center p-3 rounded-xl my-5 bg-[#030303]">
						<div className="wrap flex items-center gap-2 text-white">
							<h1 className="text-primary text-2xl font-bold">
								Product
							</h1>
							<span className="text-lg"> / Mechandanise</span>
						</div>
						<div className="wrap flex gap-4 items-center">
							<Link
								to={"#"}
								className=" bg-neutral-800 flex px-3 py-2 gap-2 rounded-xl text-white text-sm">
								<img
									src="/assets/icons/heart-plus.png"
									alt="#plus"
								/>
								<span>WishList</span>
							</Link>
							<Link
								to={"#"}
								className=" bg-primary flex px-3 py-2 gap-2 rounded-xl text-black text-sm"
								onClick={() => setShow2(!show2)}>
								<img src="/assets/icons/cart.png" alt="#plus" />
								<span>Cart</span>
							</Link>
						</div>
					</div>

					<div className="grid grid-cols-2 lg:grid-cols-4 bg-neutral-700 rounded-xl p-5 gap-5 lg:gap-10">
						{dum.map((i, index) => (
							<div
								className="wrap"
								onClick={() => setShow(!show)}
								key={index}>
								<ProductCard
									price="34,000"
									name="Defi Summer - totes bag"
								/>
							</div>
						))}
					</div>
				</Container>
			</HnF>
		</div>
	);
}
